<template>
  <div>
    <p>
      LEGO fans of all ages love to show off their incredible creations online. They have a blast coming up with original ideas, making amazing masterpieces and uploading them to the web. The internet can allow fans to share LEGO building techniques or just shout, "Look what I made!" The LEGO Universe Creation Lab also lets fans inspire the development of a truly original online game!
    </p>
    <p>
      Imagine if you took the time to build and upload a super cool LEGO creation, and then someone else copied it and took all the credit. Ouch, right? Unfortunately, that's what a few folks try to do in the Creation Lab. That's against the <router-link :to="{ name: 'creation-lab-rules' }">rules</router-link> and, more importantly, it's not nice.<br> <br>Our moderators go to great lengths to ensure that every creation featured in the Creation Lab is an original, but we may not get it right every time. If you think that someone is showing off something that isn't their own in the Creation Lab, please tell us in a comment. We'll take action on any creation or image that seems to have been swiped.
    </p>
    <p>
      Let's all help keep the LEGO Universe Creation Lab a spot for sharing our creativity and inspiring the game! Play well and keep the fun flowing!
    </p>
  </div>
</template>
